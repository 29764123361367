import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class ApplicationInsightsLoggerService {
    private appInsights: ApplicationInsights;
    constructor() {
        this.appInsights = new ApplicationInsights({
            config : {
                connectionString: environment.azure.appInsights.instrumentationKey,
                enableAutoRouteTracking: false
            }
        });
        this.appInsights.loadAppInsights();
    }

    setUser(userId: string) {
        this.appInsights.setAuthenticatedUserContext(userId);
    }

    clearUser() {
        this.appInsights.clearAuthenticatedUserContext();
    }

    logTrace(message: string, properties?: {[key: string]:any}) {
        this.appInsights.trackTrace({ message: message }, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }

    logPageEvent(name?: string, url?: string) {
        this.appInsights.trackPageView({ name: name, uri: url });
    }
}