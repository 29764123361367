export const environment = {
  production: true,
  version: require('../../package.json').version,
  baseUrl: 'https://app-t-l-fimapi.azurewebsites.net/v1/',
  azure: {
    clientId: '768855ce-bc2b-4361-9ad6-9125b24d6a84',
    authority: 'https://login.microsoftonline.com/de810bec-18a3-479a-8c6f-185945c981d3',
    redirectUrl: `${window.location.protocol}//${window.location.host}`,
    homePage: '/home',
    scope: {
      entries: 'api://c509d436-a0ce-44e1-a32c-62ab103421f9/access_as_user',
      iterable: ''
    },
    groups: {
      localAdmin: 'Role-LocationAdmin-UsersGroups',
      corporateAdmin: 'Role-CorporateAdmin-UsersGroups',
      disbursementEntry: 'Role-DisbursementDataEntry-UsersGroups',
      receiptEntry: 'Role-ReceiptDataEntry-UsersGroups',
      reconciliation: 'Role-Reconciliation-UsersGroups'
    },
    appInsights: {
      instrumentationKey: 'InstrumentationKey=615c2e1b-7282-40df-a508-e7396ce20e90;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/;ApplicationId=762389f0-3418-4675-885c-de7f0ef04321'
    }
  },
  msg: {
    actionDue: 'Action disabled due to your permissions. To request access, contact the administrator.',
  },
  reports: {
    dailyCloseFuelDayReport: 'https://app.powerbi.com/reportEmbed?reportId=0e8bca95-052f-4230-8a2a-8cf46fc58138&autoAuth=true&ctid=de810bec-18a3-479a-8c6f-185945c981d3',
    dailyFuelReceiptsReport: 'https://app.powerbi.com/reportEmbed?reportId=d3fad92a-5bb2-4a29-9b7b-ad909ef521ef&autoAuth=true&ctid=de810bec-18a3-479a-8c6f-185945c981d3',
    dailyFuelReclassificationReport: 'https://app.powerbi.com/reportEmbed?reportId=1fd3a34f-90c3-4583-9854-56ce7896ab18&autoAuth=true&ctid=de810bec-18a3-479a-8c6f-185945c981d3',
    dailyFuelUpliftReport: 'https://app.powerbi.com/reportEmbed?reportId=ee2799d8-730d-409b-9a18-f9424336b537&autoAuth=true&ctid=de810bec-18a3-479a-8c6f-185945c981d3',
    dailyMeterSkipReport: 'https://app.powerbi.com/reportEmbed?reportId=d7bebcae-d8a5-4cbd-89f6-cb23be26107b&autoAuth=true&ctid=de810bec-18a3-479a-8c6f-185945c981d3',
    dailyOwnerBalanceReport: 'https://app.powerbi.com/reportEmbed?reportId=b28c6df8-1745-40d0-b157-a101b62c0876&autoAuth=true&ctid=de810bec-18a3-479a-8c6f-185945c981d3',
    dailyStockTransferReport: 'https://app.powerbi.com/reportEmbed?reportId=2f2de3e7-a3ce-4339-ab39-c8167bb6f71c&autoAuth=true&ctid=de810bec-18a3-479a-8c6f-185945c981d3',
    dailyUnitBalanceReport: 'https://app.powerbi.com/reportEmbed?reportId=f53b114c-630d-4374-a8c7-1ff83f55ba34&autoAuth=true&ctid=de810bec-18a3-479a-8c6f-185945c981d3',
    dailyUnitVarianceReport: 'https://app.powerbi.com/reportEmbed?reportId=b97a19ed-588d-4421-b706-59ac20d29af9&autoAuth=true&ctid=de810bec-18a3-479a-8c6f-185945c981d3'
  }
};
