import {Component, OnInit} from "@angular/core";
import { ApplicationInsightsLoggerService } from "../shared/application-insights/app-logger.service";
import { LoginService } from "../services/login.service";
import { Subject, filter, takeUntil, tap } from "rxjs";
import { LocationDateI } from "../Modals/location-DateI";
import { UserInfoI } from "../Modals/user-infoI";
import { Router } from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  userInfo: UserInfoI;
  locationInfo: LocationDateI;
  unsubscribeSubject = new Subject<boolean>();


  constructor(private loginService: LoginService, private appInsights: ApplicationInsightsLoggerService, private router: Router) {}
  ngOnInit() {

      this.loginService.userInfo$
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe((userInfo) => {
          this.userInfo = userInfo;
        });
    
      this.appInsights.logTrace(`FIM UI : User - ${this.userInfo.username} on ${this.router.url} page.`);
  }
}
