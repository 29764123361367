import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import {CommonModule} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientService} from './services/http-client/http-client.service';
import {StorageService} from './services/user/storage.service';
import {AuthService} from './services/user/auth.service';
import {AppInterceptor} from './app.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SpinnerInterceptor } from './spinner.interceptor';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { LoginModule } from './login/login.module';
import { LogoutModule } from './logout/logout.module';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { AzureAdModule } from './shared/azure/azure-ad.module';
import { HasNoRoleModule } from './has-no-role/has-no-role.module';
import { ApplicationInsightsLoggerService } from './shared/application-insights/app-logger.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    PowerBIEmbedModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    MatProgressSpinnerModule,
    LogoutModule,
    AzureAdModule,
    LoginModule,
    LogoutModule,
    HasNoRoleModule,
  ],
  providers: [
    AuthService,
    StorageService,
    HttpClientService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    },
    {
      provide: 'BASE_API_URL', useValue: environment.baseUrl
    },
    ApplicationInsightsLoggerService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
